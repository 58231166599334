import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getRuleWorkBook() {
  return fetchWrapper({
    url: `${BASE_URL}/api/rule-workbook`,
    method: "GET",
  });
}

export function useRuleWorkBook() {
  return useQuery("rule-workbooks", getRuleWorkBook, {
    onError: () => {
      console.error("Error getting Rule Work Book");
    },
  });
}
