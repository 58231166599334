import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function createWorkbook(data) {
  return await fetchWrapper({
    method: "POST",
    url: `${BASE_URL}/api/rule-workbook`,
    body: data,
  });
}

export function useCreateWorkbook() {
  const queryClient = useQueryClient();

  return useMutation((data) => createWorkbook(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("rule-workbooks");
    },
    onError: () => {
      console.error("Error: Failed to create the Workbooks.");
    },
  });
}
