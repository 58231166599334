import React from "react";
import {
  AFCheckbox,
  AFDropdown,
  AFForm,
  AFRadioButtonGroup,
  AFTextArea,
  AFTextField,
} from "sharedComponents/Form";
import { Close } from "@carbon/react/icons";
import {
  Button,
  IconButton,
  Column,
  FileUploaderDropContainer,
} from "@carbon/react";
import JsonEditor from "sharedComponents/Form/JsonEditor";

const AddNewNodeForm = ({
  onClickSideNavExpand,
  setIsSideNavExpanded,
  activeNodeData,
  selectedNode = null,
  isEditNode = false,
  setDataPackages,
  dataPackages,
  activeWorkbook,
}) => {
  const [selectedType, setSelectedType] = React.useState("json");
  const nodeTypeValues = [
    { id: 1, value: "transformation", labelText: "Transformation" },
    { id: 2, value: "validation", labelText: "Validation" },
  ];

  const typeValues = [
    { id: 1, value: "json", labelText: "JSON Rule" },
    { id: 2, value: "sql", labelText: "SQL" },
    { id: 3, value: "python", labelText: "Python" },
  ];

  const dataLevelType = [
    { id: 1, value: "Gold", labelText: "Gold" },
    { id: 2, value: "Silver", labelText: "Silver" },
    { id: 3, value: "Sandbox", labelText: "Sandbox" },
  ];

  const dataLevelOptions = dataLevelType.map((data) => ({
    value: data.id,
    label: data.labelText,
  }));

  const initialValues = {
    node_name: activeNodeData?.data.title || "",
    nodeType: "transformation",
    nodeDescription: activeNodeData?.data.description || "",
    type: "json",
    uploadJson: "",
    dataLevel: activeNodeData?.data.type || "",
    persistState: "",
    uploadPython: "",
  };
  const handleEditorChange = (value) => {
    console.log("Updated File:", value);
  };

  const handleSubmit = (value) => {
    const dataPackageIndex = dataPackages.findIndex(
      (pkg) => pkg.dataPackageID === activeWorkbook.dataPackageID
    );
    if (dataPackageIndex !== -1) {
      const updatedDataPackages = [...dataPackages];
      const dataPackage = updatedDataPackages[dataPackageIndex];

      const nodeToUpdate = dataPackage.workbookData.find(
        (node) => node.id === activeNodeData.id
      );

      if (nodeToUpdate) {
        nodeToUpdate.title = value.node_name;
        nodeToUpdate.category = value.dataLevel.label;
        nodeToUpdate.description = value.nodeDescription;

        setDataPackages(updatedDataPackages);
        setIsSideNavExpanded(false);
      }
    }
  };

  const handleValueChange = (value) => {
    setSelectedType(value);
  };

  return (
    <div className="workbookDrawerModal">
      <AFForm initialValues={initialValues} onSubmit={handleSubmit}>
        <Column
          span={16}
          className="flex_between"
          style={{ padding: "10px 0px" }}
        >
          <h3>New Node</h3>
          <IconButton
            kind="ghost"
            onClick={
              isEditNode
                ? () => setIsSideNavExpanded(false)
                : onClickSideNavExpand
            }
            label="Close"
          >
            <Close />
          </IconButton>
        </Column>

        <AFTextField name="node_name" label="Node Name" />
        <AFRadioButtonGroup
          name="nodeType"
          label="Node Type"
          options={nodeTypeValues}
          orientation={"horizontal"}
        />
        <AFTextArea
          name="nodeDescription"
          label="Node Description"
          enableCounter={true}
        />
        <AFRadioButtonGroup
          name="type"
          label="Type"
          options={typeValues}
          orientation={"horizontal"}
          handleValueChange={handleValueChange}
        />
        {selectedType === "json" ? (
          <JsonEditor
            name={"uploadJson"}
            value={initialValues.uploadJson}
            onChange={(value) => handleEditorChange(value)}
            accept={[".json"]}
            label={
              <>
                <p className="cds--file--label">Json rule</p>
                <p className="cds--label-description">
                  Max file size is 500kb. Supported file types are .json
                </p>
              </>
            }
          />
        ) : selectedType === "sql" ? (
          <AFTextArea name={"sql"} label={"SQL"} enableCounter={false} />
        ) : (
          <JsonEditor
            name={"uploadJson"}
            value={initialValues.uploadJson}
            onChange={(value) => handleEditorChange(value)}
            accept={[".py"]}
            label={
              <>
                <p className="cds--file--label">Python</p>
                <p className="cds--label-description">
                  Max file size is 500kb. Supported file types are .py
                </p>
              </>
            }
          />
        )}
        <AFDropdown
          label="Data Level"
          name="dataLevel"
          options={dataLevelOptions}
        />
        <AFCheckbox name={"persistState"} label={"Persist State"} />
        <Column span={16} className="flex_between">
          <Button
            kind="ghost"
            onClick={
              isEditNode
                ? () => setIsSideNavExpanded(false)
                : onClickSideNavExpand
            }
          >
            Cancel
          </Button>
          <Button type="submit">Create</Button>
        </Column>
      </AFForm>
    </div>
  );
};

export default AddNewNodeForm;
